import EA from '../Markup/assets/img/EA-Logo.png';
import BD from '../Markup/assets/img/Betterdocs-icon.png';
import NX from '../Markup/assets/img/NotificationX.svg';
import EP from '../Markup/assets/img/embedpress-icon.svg';
import BL from '../Markup/assets/img/betterlinks-icon.png';
import SP from '../Markup/assets/img/wp-scheduled-posts-logo.png';
import TM from '../Markup/assets/img/Templately.svg';
import EJ from '../Markup/assets/img/easyjobs-icon-blue.svg';
import EB from '../Markup/assets/img/essential-blocks-icon.png';
import BP from '../Markup/assets/img/better-payment.png';

export const lifetimeProducts = [
	{
		name: 'Essential Addons',
		icon: EA,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'NotificationX',
		icon: NX,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'BetterDocs',
		icon: BD,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'EmbedPress',
		icon: EP,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'SchedulePress',
		icon: SP,
		priceName: 'Lifetime Unlimited',
	},

	{
		name: 'Better Payment',
		icon: BP,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'Essential blocks',
		icon: EB,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'BetterLinks',
		icon: BL,
		priceName: 'Lifetime Unlimited',
	},
	{
		name: 'Templately',
		icon: TM,
		priceName: 'Lifetime',
	},
	{
		name: 'easy.jobs',
		icon: EJ,
		priceName: 'Growth Yearly',
	},
];
export const yearlyProducts = [
	{
		name: 'Essential Addons',
		icon: EA,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'NotificationX',
		icon: NX,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'BetterDocs',
		icon: BD,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'EmbedPress',
		icon: EP,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'SchedulePress',
		icon: SP,
		priceName: 'Unlimited Sites Yearly',
	},

	{
		name: 'Better Payment',
		icon: BP,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'Essential blocks',
		icon: EB,
		priceName: 'Unlimited Sites',
	},
	{
		name: 'BetterLinks',
		icon: BL,
		priceName: 'Unlimited Sites Yearly',
	},
	{
		name: 'Templately',
		icon: TM,
		priceName: 'Yearly',
	},
	{
		name: 'easy.jobs',
		icon: EJ,
		priceName: 'Growth Yearly',
	},
];
